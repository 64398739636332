<template>
  <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
    <SubHeader title="상품관리"></SubHeader>
    <div class="mx-3 bg-body rounded-4 shadow-400">
            <div class="my-2 pb-2">
                <ul class="mx-3 last-border-0" v-if="list&&list.length>0">
                    <li class="px-2 py-3 flex-between-center border-bottom border-gray-relative-200" v-for="(item,index) in list" :key="index">
                        <div class="flex-between-center">
                            <div class="d-flex flex-column fs-px-13">
                                <small class="text-gray-relative-500">{{item.text}}</small>
                            </div>
                        </div>
                        <div class="flex-between-center">
                            <div class="d-flex flex-column border-end pe-3">
                                <small class="text-end text-danger">{{ item.price.toLocaleString() }}원</small>
                            </div>
                            <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="EditItem(item)">
                                <i class="fal fa-edit text-muted"></i>
                            </span>
                            <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="RemoveConfirmItem(item)">
                                <i class="fal fa-trash text-muted"></i>
                            </span>
                        </div>
                    </li>
                </ul>
                <div class="p-3 py-6 text-center text-gray-relative-500" v-else>
                    <i class="fal fa-shopping-cart fa-3x text-gray-relative-400 d-block my-3"></i>
                    <p class="my-2 fs-px-13">상품 목록이 없어요.<br>상품을 추가해주세요.</p>
                </div>
            </div>
        </div>
    
    <div class="mx-3 rounded-3 bg-body shadow-200">
      <h6 class="fw-bold p-3">상품 정보</h6>
          <hr class="m-0 border-gray-relative-400">

          <div class="p-3">
              <!-- 고객이름 -->
              <div class="form-group">
                  <label class="small text-gray-relative-600">상품 이름</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="상품 이름" v-model="name">
              </div>
              <!-- 고객전화번호 -->
              <div class="form-group mt-3">
                  <label class="small text-gray-relative-600">금액</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="number" placeholder="상품 금액" v-model="price">
              </div>
          </div>

      </div>

      <div @click="ItemAdd()" class="rounded-3 btn thick btn-main w-100 mt-4" v-if="mode =='N'">상품추가</div>
      <div class="border-top p-3 border-gray-relative-200" v-if="mode=='E'">
          <ul class="d-flex">
              <li class="rounded-3 p-2 text-center bg-gray-relative-200 flex-grow-1 me-1" @click="ResetInfo()">취소</li>
              <li class="rounded-3 p-2 text-center bg-main flex-grow-1 text-white"  @click="ItemEdit()">상품수정</li>
          </ul>
      </div>
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");

export default {
  components: {
      SubHeader
  },
  data(){
      return{
        mode : 'N',
        name : '',
        price : '',
        list : [],
        t_code : '',
      }
  },
  mounted(){
    this.GetItemList();
  },
  methods:{
    RemoveConfirmItem(item){
      this.t_code = item.code;
      this.$confirm(`상품을 삭제하시겠습니까?`).then((result)=>{
        if( result.isOk){
          this.ItemRemove()
        }
      })
    },
    ResetInfo(){
      this.mode = 'N';
      this.name = '';
      this.price = '';
      this.t_code = '';
    },
    EditItem(item){
      this.name = item.text;
      this.price = item.price;
      this.t_code = item.code;
      this.mode = 'E';


    },
    ItemRemove(){
      
      const code = this.t_code;
      const body = {code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/item/RemoveItem',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("상품을 삭제하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                    this.GetItemList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    ItemEdit(){
      const name = this.name;
      const price = this.price;
      const code = this.t_code;
      if(name ==''){
        this.$alert("상품이름을 입력해주세요.")
        return false
      }

      if(price ==''){
        this.$alert("상품 금액을 입력해주세요.")
        return false
      }
      const body = {name,price,code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/item/EditItem',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("상품을 수정하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                    this.GetItemList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    ItemAdd(){
      const name = this.name;
      const price = this.price;
      
      if(name ==''){
        this.$alert("상품이름을 입력해주세요.")
        return false
      }

      if(price ==''){
        this.$alert("상품 금액을 입력해주세요.")
        return false
      }
      const body = {name,price};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/item/AddItem',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("상품을 추가하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                      this.GetItemList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    GetItemList(){
      
      const body = {};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/item/GetItemList',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              const e_body = res.data.body;
              const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res)
              this.list = body.list;
            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    }   
  }
}
</script>

<style>

</style>